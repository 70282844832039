import {
  signIn,
  signOut,
  getToken,
  isSignedIn,
  getAuthorization,
  getAuthorizationHeader,
  getCanonicalId,
  getRecentAnonymousIds,
  registerAuthConfigWithOptions,
  getEventTargetInstance,
  UserIdentityEvent as originIdentityEvent,
  type TokenAdditionalInfo,
  getIdentity
} from '@cimpress-technology/authfront-sdk-core'

import { TOKEN_DETAILS_STORAGE_KEY, VP_CLIENT_ID, OCI_BASE_URL, VP_CONNECTION_NAME, WAS_TOKEN_CLAIM } from '../common/constants'

import type { UserSignInOptions, UserSignOutPayload, AuthorizationInfo, UserIdentityEvent, ErrorAndResultCallbackFn, UrlParam } from '../common/types'

import { generateFinalRedirectURL, buildPath, buildWAuth, validateSignInParams } from './utils/helper'

type Config = {
  clientId?: string,
  oauthBaseURL?: string,
  tokenAdditionalInfo: TokenAdditionalInfo,
  culture: string
  returnTo: string
  connection?: string
}

class AuthService {
  private static _eventTargetInstance: any | null = null
  private readonly culture: string
  private readonly returnTo: string
  private readonly connection: string | undefined

  private static get eventTargetInstance (): EventTarget {
    if (!this._eventTargetInstance) {
      this._eventTargetInstance = getEventTargetInstance()
    }
    return this._eventTargetInstance!
  }

  constructor (authObject: Config, callback?: ErrorAndResultCallbackFn<originIdentityEvent>) {
    const { clientId, oauthBaseURL, tokenAdditionalInfo, culture, returnTo, connection } = authObject
    this.culture = culture
    this.returnTo = returnTo
    this.connection = connection

    localStorage.setItem(TOKEN_DETAILS_STORAGE_KEY, JSON.stringify(tokenAdditionalInfo))
    registerAuthConfigWithOptions(
      {
        clientId: clientId || VP_CLIENT_ID,
        oauthBaseURL: oauthBaseURL || OCI_BASE_URL,
        useBuiltInCallback: false,
        generateAnonIdentity: !this.connection,
        storeRefreshTokenAsCookie: true,
        extendBrowserSession: true
      }, callback)
  }

  isSignedIn = (): boolean => isSignedIn()

  getToken = (): string | undefined => getToken()

  getTokenType = (): string | undefined => getAuthorizationHeader()

  getCanonicalId = (): string | undefined => getCanonicalId()

  getProfile = (): object | undefined => {
    const identity = this.getIdentity()
    return identity?.profile
  }

  getIdentity = (): UserIdentityEvent | undefined => {
    const identity = getIdentity()
    if (!identity) return undefined

    return {
      ...identity,
      accessToken: this.getToken()!,
      recentAnonymousIds: this.getRecentAnonymousIds()!,
      authorizationHeader: this.getAuthorizationHeader()!,
    }
  }

  signIn = (
    additionalReturnUrlQueryParams: UrlParam[],
    additionalReturnUrlHashParams: UrlParam[],
    options: UserSignInOptions): void => {
    validateSignInParams(additionalReturnUrlQueryParams, additionalReturnUrlHashParams, options)

    const postLoginRedirectToPage = buildPath(additionalReturnUrlQueryParams, additionalReturnUrlHashParams)

    // care connection waad does not require wauth param
    let wauth
    if (!this.connection) {
      wauth = buildWAuth(options, generateFinalRedirectURL(postLoginRedirectToPage), this.getCanonicalId(), this.getToken(), this.culture)
    }
    return signIn({ postLoginRedirectToPage, connection: this.connection ? this.connection : VP_CONNECTION_NAME, hostedCallbackPageURL: generateFinalRedirectURL(postLoginRedirectToPage), wauth })
  }

  getAuthorizationHeader = (): string | undefined => {
    const header = this.getAuthorization()
    return header ? `${header.type} ${header.token}` : undefined
  }

  signOut = (option?: UserSignOutPayload): void => {
    signOut({ returnTo: option ? option.returnTo : this.returnTo })
  }

  getAuthorization = (): AuthorizationInfo | undefined => getAuthorization()

  onUserIdentityUpdate = (callback: (result?: any) => void): void => {
    if (typeof callback !== 'function') { return }
    AuthService.eventTargetInstance.addEventListener('userIdentity', (e: any) => {
      const userDetails = e.detail
      const authorization = userDetails.authorization

      const updatedIdentity = {
        ...userDetails,
        accessToken: authorization?.token,
        recentAnonymousIds: userDetails?.profile ? userDetails.profile[WAS_TOKEN_CLAIM] : undefined,
        authorizationHeader: authorization ? `${authorization.type} ${authorization.token}` : undefined
      }
      callback(updatedIdentity)
    })
  }

  getRecentAnonymousIds = (): string[] | undefined => getRecentAnonymousIds()
}

export { AuthService, type Config }
