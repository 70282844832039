import { OPTIMIZELY_COOKIE_NAME, TEST_COOKIE_NAME, validNavHintValues } from '../../common/constants'
import { UrlParam, UserSignInOptions } from '../../common/types'

export function generateFinalRedirectURL (pathName: string = ''): string {
  const location = getWindowLocation()
  return location.origin + '/' + pathName.replace(/^\//, '')
}

export function setCookie (name: string, value: string, expiresAt?: Date) {
  let cookieString = `${name}=${encodeURIComponent(value)}; Path=/`
  if (expiresAt) {
    cookieString += ` ;Expires=${expiresAt.toUTCString()}`
  }

  document.cookie = cookieString
}

export function getCookie (name: string): string | undefined {
  const nameEQ = `${name}=`
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.indexOf(nameEQ) === 0) {
      return decodeURIComponent(cookie.substring(nameEQ.length))
    }
  }
  return undefined
}

export function deleteCookie (name: string) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT;`
}

export function getWindow (): any {
  return window
}

export function getWindowLocation (): Location {
  return getWindow().location
}

export function buildPath (
  additionalReturnUrlQueryParams: UrlParam[],
  additionalReturnUrlHashParams: UrlParam[]
): string {
  let pathName = '/'// Default base path

  const queryString = additionalReturnUrlQueryParams
    .map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')

  const hashString = additionalReturnUrlHashParams
    .map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')

  if (queryString) {
    pathName += `?${queryString}`
  }

  if (hashString) {
    pathName += `#${hashString}`
  }

  return pathName.replace(/^\//, '') // Ensure it integrates well with `location.origin`
}

export function buildWAuth (options: UserSignInOptions, returnUrl: string, canonicalId: string | undefined, token: string | undefined, culture: string): string {
  const state = {
    ...options,
    redirectUrl: returnUrl,
    originUrl: getWindowLocation().origin,
    visit: getCookie('visit'),
    ga: getCookie('_ga'),
    optimizelyId: getCookie(TEST_COOKIE_NAME) || getCookie(OPTIMIZELY_COOKIE_NAME),
    anonymousId: canonicalId,
    anonReferenceToken: token,
    culture,
    guestReturnUrl: options.guestReturnUrl ? options.guestReturnUrl : returnUrl,
    site: options.site || defaultSite()
  }
  return btoa(JSON.stringify(state))
}

export function validateSignInParams (additionalReturnUrlQueryParams: unknown, additionalReturnUrlHashParams: unknown, options: unknown) {
  if (!isQueryParamArray(additionalReturnUrlQueryParams)) {
    throw new Error('Invalid additionalReturnUrlQueryParams')
  }
  if (!isQueryParamArray(additionalReturnUrlHashParams)) {
    throw new Error('Invalid additionalReturnUrlHashParams')
  }
  if (!validateUserSignInOptions(options)) {
    throw new Error('Invalid UserSignInOptions')
  }
}

function defaultSite (): string {
  const { host = '' } = window.location
  const tokens = host.split('.')

  const domainMapping: Record<string, string> = {
    promotique: 'Promotique',
    '99designs': '99designs',
    vistacreate: 'vistacreate',
  }

  return tokens.find((token) => domainMapping[token]) ? domainMapping[tokens.find((token) => domainMapping[token])!] : 'Vistaprint'
}

const NonEmptyString = (value: unknown): value is string => typeof value === 'string' && value.length > 0
const isBoolean = (value: unknown): value is boolean => typeof value === 'boolean'
const isQueryParamArray = (value: unknown): value is UrlParam[] => Array.isArray(value) && value.every(item => NonEmptyString(item.key) && NonEmptyString(item.value))

function validateUserSignInOptions (options: unknown): options is UserSignInOptions {
  if (typeof options !== 'object' || options === null) return false

  const allowedKeys: (keyof UserSignInOptions)[] = [
    'allowGuestUser', 'guestReturnUrl', 'site', 'skipFasterCheckoutText',
    'loginContext', 'navHint', 'enableGoogleOneTap',
    'enforcedEmail', 'customText', 'isFirstLogin', 'isSteppedUp',
    'unificationEntityId', 'vcsStoreUrl', 'testUserId', 'storeId',
    'vcsStoreLogoUrl', 'restrictSignup', 'disableEmailField'
  ]

  return Object.entries(options).every(([key, value]) => {
    if (!allowedKeys.includes(key as keyof UserSignInOptions)) return false

    if (typeof value === 'undefined') return true

    if (key === 'navHint') {
      return validNavHintValues.includes(value)
    }
    if (
      ['guestReturnUrl', 'site', 'loginContext', 'enforcedEmail', 'customText', 'unificationEntityId', 'vcsStoreUrl', 'testUserId', 'storeId', 'vcsStoreLogoUrl']
        .includes(key)
    ) {
      return NonEmptyString(value)
    }

    if (
      ['allowGuestUser', 'skipFasterCheckoutText', 'requireSession', 'enableGoogleOneTap', 'isFirstLogin', 'isSteppedUp', 'restrictSignup', 'disableEmailField']
        .includes(key)
    ) {
      return isBoolean(value)
    }
    return false
  })
}
