import {
  registerTokenStorage,
} from '@cimpress-technology/authfront-sdk-core'

import { CookieTokenStorage } from './utils/storage'

import type {
  UserSignInOptions, UserSignOutPayload, SESSION_TYPE, UserTokenContext, UserIdentityEvent, AuthorizationInfo,
  ErrorAndResultCallbackFn, UrlParam, NavHint
} from '../common/types'

import { AuthService, Config } from './auth'

registerTokenStorage(new CookieTokenStorage())

export {
  AuthService,
  NavHint,
  type UrlParam,
  type Config,
  type UserSignInOptions,
  type UserSignOutPayload,
  type SESSION_TYPE,
  type UserTokenContext,
  type UserIdentityEvent,
  type AuthorizationInfo,
  type ErrorAndResultCallbackFn,
}
