import {
  TokenStorage,
  type TokenAdditionalInfo,
} from '@cimpress-technology/authfront-sdk-core'

import { setCookie, getCookie, deleteCookie } from './helper'

import {
  ACCESS_TOKEN_COOKIE_KEY,
  REFRESH_TOKEN_COOKIE_KEY,
  TOKEN_DETAILS_STORAGE_KEY,
  CODE_VERIFIER,
  ACCESS_TOKEN_LIFE_TIME_KEY
} from '../../common/constants'

export class CookieTokenStorage extends TokenStorage {
  saveAccessToken (accessToken: string, expiresAt: Date, tokenType: string): void {
    const epochSeconds = Math.floor(expiresAt.getTime() / 1000)
    setCookie(ACCESS_TOKEN_COOKIE_KEY, accessToken, expiresAt)
    setCookie(ACCESS_TOKEN_LIFE_TIME_KEY, epochSeconds.toString(), expiresAt)
  }

  retrieveAccessToken (): string | undefined {
    return getCookie(ACCESS_TOKEN_COOKIE_KEY)
  }

  deleteAccessToken (): void {
    deleteCookie(ACCESS_TOKEN_COOKIE_KEY)
    deleteCookie(ACCESS_TOKEN_LIFE_TIME_KEY)
  }

  saveRefreshToken (refreshToken: string, expiresAt: Date, saveAsCookie: boolean): void {
    setCookie(REFRESH_TOKEN_COOKIE_KEY, refreshToken)
  }

  retrieveRefreshToken (): string | undefined {
    return getCookie(REFRESH_TOKEN_COOKIE_KEY)
  }

  deleteRefreshToken (): void {
    deleteCookie(REFRESH_TOKEN_COOKIE_KEY)
  }

  saveAdditionalInfo (payload: TokenAdditionalInfo): void {
    localStorage.setItem(this.getAdditionalInfoStorageKey(), JSON.stringify(payload))
  }

  retrieveAdditionalInfo (): TokenAdditionalInfo | undefined {
    const stored = localStorage.getItem(this.getAdditionalInfoStorageKey())
    return stored ? JSON.parse(stored) as TokenAdditionalInfo : undefined
  }

  deleteAdditionalInfo (): void {
    localStorage.removeItem(this.getAdditionalInfoStorageKey())
  }

  getAdditionalInfoStorageKey (): string {
    return TOKEN_DETAILS_STORAGE_KEY
  }

  getCodeVerifier (): string {
    return getCookie(CODE_VERIFIER) ?? ''
  }

  saveCodeVerifier (codeVerifier: string): void {
    setCookie(CODE_VERIFIER, codeVerifier)
  }

  deleteCodeVerifier (): void {
    deleteCookie(CODE_VERIFIER)
  }
}
